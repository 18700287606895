import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import PostLayout from '../components/Layout/PostLayout';
import { SideMenu } from '../components/SideMenu';
import PostTemplateDetails from '../components/pages/PostTemplateDetails';
import { BookCation } from '../components/SideMenu/BookCaption';

type IProps = {
  data: any;
};

export const BookPageTemplate: React.FC<IProps> = (props) => {
  const { title } = props.data.site.siteMetadata;

  return (
    <PostLayout>
      <div className="columns">
        {/*<SideMenu />*/}
        <div className="column is-3-tablet">
          <BookCation title={title} parts={props.data.jsonJson.parts} />
        </div>
        <div className="column is-9-tablet is-9-desktop is-9-widescreen">
          <Helmet>{/*<title>{`${postTitle} - ${title}`}</title>*/}</Helmet>
          <PostTemplateDetails {...props} />
        </div>
      </div>
    </PostLayout>
  );
};

export default BookPageTemplate;

export const pageQuery = graphql`
  query BookBySlug($slug: String, $bookName: String) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        tagSlugs
        slug
      }
      frontmatter {
        title
        tags
        date
        description
      }
    }
    jsonJson(title: { eq: $bookName }) {
      title
      slug
      parts {
        books {
          slug
          title
        }
        title
      }
    }
  }
`;
