import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import styled from 'styled-components';
import { Card, CardContent } from '../common/Card';

type IProps = {
  title: string;
  parts: any;
};

export const BookCation: React.FC<IProps> = ({ title, parts }) => {
  return (
    <Card>
      <CardContent>
        <Title>チャプター</Title>
        <MenuList>
          {parts.map(({ title, books }: any) => {
            return (
              <li key={title}>
                {title}
                <ul>
                  {books.map(({ title, slug }: any) => {
                    return (
                      <Item key={title}>
                        <CategoryLink to={slug} activeClassName="is-active">
                          <span>
                            <CategoryTitle>{title}</CategoryTitle>
                          </span>
                        </CategoryLink>
                      </Item>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </MenuList>
      </CardContent>
    </Card>
  );
};

const Title = styled.h3`
  line-height: normal;
  margin-top: 0;
`;

const MenuList = styled.ul`
  margin-bottom: 0;
  list-style: none;
`;

const Item = styled.li`
  margin-bottom: 0;
  margin-left: 20px;
`;

const CategoryLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  color: #363636;
  padding: 8px 0;
  font-size: 14px;
  &:hover {
    background-color: whitesmoke;
    color: #363636;
  }
  &.is-active {
    background-color: whitesmoke;
  }
`;

const CategoryTitle = styled.span`
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
`;

const CategoryCount = styled.span`
  align-items: center;
  background-color: whitesmoke;
  border-radius: 0.25rem;
  color: #4a4a4a;
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
`;
